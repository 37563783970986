import {useCallback} from 'react';

import {envIsDev, envIsPublicCloud} from './../../config';
import {getDatadogRum} from './../../util/datadog';
import {getLinesFromData} from './../../util/plotHelpers';

type ProfiledFunction<T extends (...args: any[]) => any> = T;

const VITAL_DESCRIPTION = 'Panel line construction duration';

export function useSamplingLineProfile(
  fn: typeof getLinesFromData,
  meta: {
    isInReport: boolean;
  },
  debugMode = false,
  DD_RUM: undefined | null | ReturnType<typeof getDatadogRum> = getDatadogRum()
): ProfiledFunction<typeof getLinesFromData> {
  const profiledFn = useCallback(
    (
      ...args: Parameters<typeof getLinesFromData>
    ): ReturnType<typeof getLinesFromData> => {
      const t0 = performance.now();
      const result = fn(...args);
      const t1 = performance.now();

      // we can eject from all the logging by passing `null` to DD_RUM
      if (!DD_RUM) {
        return result;
      }

      try {
        const numPoints = args[1].data.reduce(
          (acc, run) => acc + run.history.length,
          0
        );

        const data = {
          duration: Math.ceil(t1 - t0),
          description: VITAL_DESCRIPTION,
          startTime: Date.now() - Math.ceil(t1 - t0),
          config: {
            usesAreaChart:
              args[2].plotType === 'stacked-area' ||
              args[2].plotType === 'pct-area',
            // usesGrouping: flags.usesGrouping,
            // usesTimeConversion: flags.usesTimeConversion,
            environment: envIsPublicCloud ? 'public-cloud' : 'local',
            isInReport: !!meta.isInReport,
            type: 'sampling',
            usesSmoothing: args[2].smoothingParam > 0,
            usesXExpression: args[2].xExpression != null,
            usesYExpressions: args[2].expressions.length > 0,
            windowing: !!args[2].windowing,
          },
          meta: {
            numPoints,
            numRuns: args[1].data.length,
            // density is a percentage of points per bucket per history
            // we want to be able to compare lines with full buckets to be able
            // to compare relative performance of bucket sizes

            density: (numPoints / args[1].data.length / 1500) * 100,
          },
          timing: {
            // we currently can't instrument inside to get this splits
            // areaConversion: Math.floor(t9 - t8),
            // grouping: Math.floor(t4 - t3a),
            // parseHistory: Math.floor(t2 - t1),
            // smoothing: Math.floor(t7 - t6),
            // timestampConversion: Math.floor(t3a - t3),
            // xAxisExpression: Math.floor(t5 - t4),
            // yExpressions: Math.floor(t6 - t5),
          },
        };

        if (debugMode && envIsDev && t1 - t0 > 1) {
          console.log(
            `Sampling line construction for ${
              args[2].yAxis[0]
            } execution took ${t1 - t0} milliseconds`,
            data
          );
        }
        if (t1 - t0 > 1) {
          DD_RUM.addDurationVital(VITAL_DESCRIPTION, data);
        }
      } catch (err) {
        console.error('Vis Team Error: Error logging vital', err);
      }

      return result;
    },
    [fn, debugMode, DD_RUM, meta]
  ) as ProfiledFunction<typeof getLinesFromData>;

  return profiledFn;
}
/**
 * Generates custom performance metrics for analysis in Datadog: https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/#create-custom-performance-metrics
 */
export async function logVitals(
  spans: Record<string, number>,
  flags: {
    isInReport: boolean;
    usesAreaChart: boolean;
    usesGrouping: boolean;
    usesSmoothing: boolean;
    usesTimeConversion: boolean;
    usesXExpression: boolean;
    usesYExpressions: boolean;
  },
  vitals: {
    numBucketsByPanelWidth: number;
    numRuns: number;
    numPoints: number;
  },
  debugMode = false,
  DD_RUM = getDatadogRum()
) {
  const {t0, t1, t2, t3, t3a, t4, t5, t6, t7, t8, t9} = spans;
  if (!DD_RUM) {
    return;
  }

  const density = Math.floor(
    (vitals.numPoints / vitals.numRuns / vitals.numBucketsByPanelWidth) * 100
  );
  const data = {
    // have to use epoch time for datadog here
    startTime: Date.now() - Math.ceil(t9 - t0),
    duration: Math.ceil(t9 - t0),
    description: VITAL_DESCRIPTION,
    // we use Math.floor to reduce fractional measures to 0
    context: {
      config: {
        environment: envIsPublicCloud ? 'public-cloud' : 'local',
        type: 'full-fidelity',
        isInReport: flags.isInReport,
        usesAreaChart: flags.usesAreaChart,
        usesGrouping: flags.usesGrouping,
        usesSmoothing: flags.usesSmoothing,
        usesTimeConversion: flags.usesTimeConversion,
        usesXExpression: flags.usesXExpression,
        usesYExpressions: flags.usesYExpressions,
      },
      meta: {
        // density is a percentage of points per bucket per history
        // we want to be able to compare lines with full buckets to be able
        // to compare relative performance of bucket sizes
        density,
        numBucketsByPanelWidth: vitals.numBucketsByPanelWidth,
        numRuns: vitals.numRuns,
        numPoints: vitals.numPoints,
      },
      timing: {
        areaConversion: Math.floor(t9 - t8),
        grouping: Math.floor(t4 - t3a),
        parseHistory: Math.floor(t2 - t1),
        smoothing: Math.floor(t7 - t6),
        timestampConversion: Math.floor(t3a - t3),
        xAxisExpression: Math.floor(t5 - t4),
        yExpressions: Math.floor(t6 - t5),
      },
    },
  };
  if (debugMode && envIsDev) {
    console.log(`FF line construction took: `, t9 - t0, data);
  }
  DD_RUM.addDurationVital('useLinesConstruction', data);
}
